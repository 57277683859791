import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Spinner from "../app/shared/Spinner";

const Dashboard = lazy(() => import("./dashboard/Dashboard"));

// Admin routes

const AdminList = lazy(() => import("./components/admin/AdminList.js"));
const CreateAdmin = lazy(() => import("./components/admin/CreateAdmin.js"));
const CreateEvent = lazy(() => import("./components/events/CreateEvent.js"));
const UpdateEvent = lazy(() => import("./components/events/UpdateEvent.js"));
const UserList = lazy(() => import("./components/user/UserList.js"));
const ArtistList = lazy(() => import("./components/Artists/ArtistList.js"));
const OrderList = lazy(() => import("./components/Orders/OrderList.js"));
const OrderDetails = lazy(() => import("./components/Orders/OrderDetails.js"));
const CreateArtist = lazy(() => import("./components/Artists/CreateArtist.js"));
const UpdateArtist = lazy(() => import("./components/Artists/UpdateArtist.js"));
const EventList = lazy(() => import("./components/events/EventList.js"));
// Admin routes

const Buttons = lazy(() => import("./basic-ui/Buttons"));
const Dropdowns = lazy(() => import("./basic-ui/Dropdowns"));
const Typography = lazy(() => import("./basic-ui/Typography"));

const BasicElements = lazy(() => import("./form-elements/BasicElements"));

const BasicTable = lazy(() => import("./tables/BasicTable"));

const Mdi = lazy(() => import("./icons/Mdi"));

const ChartJs = lazy(() => import("./charts/ChartJs"));

const Error404 = lazy(() => import("./error-pages/Error404"));
const Error500 = lazy(() => import("./error-pages/Error500"));

const Login = lazy(() => import("./user-pages/Login"));
const Register1 = lazy(() => import("./user-pages/Register"));

class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path="/login" component={Login} />
          {/* <Route exact path="/register" component={ Register1 } /> */}
          <Route exact path="/dashboard" component={Dashboard} />

          <Route path="/admin/list" component={AdminList} />
          <Route path="/user/list" component={UserList} />
          <Route path="/event/list" component={EventList} />
          <Route path="/artist/list" component={ArtistList} />
          <Route path="/order/list" component={OrderList} />
          <Route path="/order/details" component={OrderDetails} />

          <Route path="/admin/add" component={CreateAdmin} />
          <Route path="/add-event" component={CreateEvent} />
          <Route path="/add-artist" component={CreateArtist} />
          <Route path="/update-artist" component={UpdateArtist} />
          <Route path="/update-event" component={UpdateEvent} />
          <Route path="/basic-ui/buttons" component={Buttons} />
          <Route path="/basic-ui/dropdowns" component={Dropdowns} />
          <Route path="/basic-ui/typography" component={Typography} />

          <Route path="/basic-ui/buttons" component={Buttons} />
          <Route path="/basic-ui/dropdowns" component={Dropdowns} />
          <Route path="/basic-ui/typography" component={Typography} />

          <Route
            path="/form-Elements/basic-elements"
            component={BasicElements}
          />

          <Route path="/tables/basic-table" component={BasicTable} />

          <Route path="/icons/mdi" component={Mdi} />

          <Route path="/charts/chart-js" component={ChartJs} />

          {/* <Route path="/user-pages/login-1" component={ Login } /> */}
          {/* <Route path="/user-pages/register-1" component={ Register1 } /> */}

          <Route path="/error-pages/error-404" component={Error404} />
          <Route path="/error-pages/error-500" component={Error500} />

          <Redirect to="/login" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
